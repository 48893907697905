import _ from 'lodash';
import { FacilityType, Obid, Plmn } from 'types';

import { RESET_STORE } from '../../../../../store/actions/reduxActions';
import { NOT_GROUPED_ID } from '../../constants';
import * as Actions from '../actions';
import {
  AssignFacilitiesToGroupActionProps,
  EditFacilityDialogDataProps,
  FacilityCreationStatus,
  NestedFacilitiesStatistics
} from '../types';

export const SELECTED_ALL_PARTNER_ID = -1;

type CurrentlyUsedOperators = {
  [key: string]: number;
};

interface PairDevicesFailedSerial {
  serial: string;
  reason: string;
}

interface InitialState {
  entities: NestedFacilitiesStatistics;
  isFetchEntitiesLoading: boolean;
  searchText: string;
  addNewObjectLoading: boolean;
  addManagerDialogOpen: boolean;
  mobileNetworkOperatorsDialogOpen: boolean;
  mobileNetworkOperatorsDialogSelectedObid: Obid | null;
  mobileNetworkOperatorsDialogSelectedObjectCountry: string | null;
  mobileNetworkOperatorsDialogSelectedObjectSimCards: string[];
  mobileNetworkOperatorsDialogSelectedObjectPlmn: string;
  mobileNetworkOperatorsDialogSelectedGroupId: number | null;
  managersSelectedObid: Obid | null;
  pairDevicesOpenDialog: boolean;
  pairDevicesSelectedObid: Obid | null;
  currentlyUsedOperators: CurrentlyUsedOperators | null;
  pairDevicesAddedSerials: string[];
  pairDevicesFailedSerials: PairDevicesFailedSerial[];
  googleApiLoading: boolean;
  objectDetailsDrawerObid: Obid | null;
  selectedPartnerId: number;
  objectDetailsDrawerOpen: boolean;
  objectDetailsDrawerGroupId: number | null;
  editFacilityDialog: {
    type: 'edit' | string;
    props: {
      open: boolean;
    };
    facilityType: FacilityType | null;
    data: EditFacilityDialogDataProps | null;
  };
}

const initialState: InitialState = {
  entities: {},
  isFetchEntitiesLoading: false,
  searchText: '',
  addNewObjectLoading: false,
  addManagerDialogOpen: false,
  mobileNetworkOperatorsDialogOpen: false,
  mobileNetworkOperatorsDialogSelectedObid: null,
  mobileNetworkOperatorsDialogSelectedObjectCountry: null,
  mobileNetworkOperatorsDialogSelectedObjectSimCards: [],
  mobileNetworkOperatorsDialogSelectedObjectPlmn: '',
  mobileNetworkOperatorsDialogSelectedGroupId: null,
  managersSelectedObid: null,
  pairDevicesOpenDialog: false,
  pairDevicesSelectedObid: null,
  currentlyUsedOperators: null,
  pairDevicesAddedSerials: [],
  pairDevicesFailedSerials: [],
  googleApiLoading: true,
  objectDetailsDrawerObid: null,
  selectedPartnerId: SELECTED_ALL_PARTNER_ID,
  objectDetailsDrawerOpen: false,
  objectDetailsDrawerGroupId: null,
  editFacilityDialog: {
    type: '',
    props: {
      open: false
    },
    facilityType: null,
    data: null
  }
};

// eslint-disable-next-line complexity, @typescript-eslint/no-explicit-any
const partnerReducer = function (state = initialState, action: any): InitialState {
  switch (action.type) {
    case Actions.GET_ENTITIES: {
      const entities: NestedFacilitiesStatistics = action.payload;

      return {
        ...state,
        entities
      };
    }
    case Actions.ADD_ENTITY: {
      const updatedEntities = _.cloneDeep(state.entities);

      updatedEntities[NOT_GROUPED_ID].facilities[action.facility.id] = {
        ...action.facility
      };

      return {
        ...state,
        addNewObjectLoading: true,
        entities: updatedEntities
      };
    }
    case Actions.SET_SELECTED_PARTNER_ID: {
      return {
        ...state,
        selectedPartnerId: action.payload
      };
    }
    case Actions.OPEN_OBJECT_DETAILS_DRAWER: {
      return {
        ...state,
        objectDetailsDrawerOpen: true,
        objectDetailsDrawerObid: action.obid,
        objectDetailsDrawerGroupId: action.groupId
      };
    }
    case Actions.CLOSE_OBJECT_DETAILS_DRAWER: {
      return {
        ...state,
        objectDetailsDrawerOpen: false,
        objectDetailsDrawerObid: null,
        objectDetailsDrawerGroupId: null
      };
    }
    case Actions.SET_IS_FETCH_ENTITIES_LOADING: {
      return {
        ...state,
        isFetchEntitiesLoading: action.payload
      };
    }
    case Actions.SET_GOOGLE_API_LOADING: {
      return {
        ...state,
        googleApiLoading: action.payload
      };
    }
    case Actions.PAIR_DEVICES_OPEN_DIALOG: {
      return {
        ...state,
        pairDevicesOpenDialog: true,
        pairDevicesSelectedObid: action.obid
      };
    }
    case Actions.PAIR_DEVICES_CLOSE_DIALOG: {
      return {
        ...state,
        pairDevicesOpenDialog: false,
        pairDevicesSelectedObid: null
      };
    }
    case Actions.EDIT_PLMN_OF_OBJECT: {
      const updatedEntities = _.cloneDeep(state.entities);

      const { groupId, obid, plmn }: { groupId: number; obid: Obid; plmn: Plmn } = action;

      updatedEntities[groupId].facilities[obid] = {
        ...updatedEntities[groupId].facilities[obid],
        additionalData: {
          ...updatedEntities[groupId].facilities[obid].additionalData,
          plmn
        }
      };

      return {
        ...state,
        entities: updatedEntities
      };
    }
    case Actions.OPEN_MOBILE_NETWORK_OPERATORS_DIALOG: {
      return {
        ...state,
        mobileNetworkOperatorsDialogOpen: true,
        mobileNetworkOperatorsDialogSelectedObid: action.obid,
        mobileNetworkOperatorsDialogSelectedGroupId: action.groupId,
        mobileNetworkOperatorsDialogSelectedObjectCountry: action.country,
        mobileNetworkOperatorsDialogSelectedObjectSimCards: action.simCards,
        mobileNetworkOperatorsDialogSelectedObjectPlmn: action.plmn
      };
    }
    case Actions.CLOSE_MOBILE_NETWORK_OPERATORS_DIALOG: {
      return {
        ...state,
        mobileNetworkOperatorsDialogOpen: false
      };
    }
    case Actions.PAIR_DEVICES_BY_SERIALS_REPORT: {
      return {
        ...state,
        pairDevicesOpenDialog: false,
        pairDevicesAddedSerials: action.payload?.addedSerials,
        pairDevicesFailedSerials: action.payload?.failedSerials
      };
    }
    case Actions.CLOSE_PAIR_DEVICE_BY_SERIAL_REPORT_DIALOG: {
      return {
        ...state,
        pairDevicesAddedSerials: [],
        pairDevicesFailedSerials: []
      };
    }
    case Actions.UPDATE_ENTITY: {
      const updatedEntities = _.cloneDeep(state.entities);

      updatedEntities[action.entity.groupId].facilities[action.entity.facilityId] = {
        ...updatedEntities[action.entity.groupId].facilities[action.entity.facilityId],
        object_name: action.entity.name,
        lang: action.entity.lang,
        facilityType: action.entity.facilityType,
        instance: action.entity.instance,
        brand: action.entity.brand,
        store_type: action.entity.storeType,
        additionalData: {
          ...updatedEntities[action.entity.groupId].facilities[action.entity.facilityId].additionalData
        }
      };

      return {
        ...state,
        entities: updatedEntities
      };
    }
    case Actions.GET_CURRENTLY_USED_OPERATORS: {
      return {
        ...state,
        currentlyUsedOperators: action.payload
      };
    }
    case Actions.OPEN_ADD_MANAGERS_DIALOG: {
      return {
        ...state,
        addManagerDialogOpen: true
      };
    }
    case Actions.CLOSE_ADD_MANAGERS_DIALOG: {
      return {
        ...state,
        addManagerDialogOpen: false
      };
    }
    case Actions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText
      };
    }
    case Actions.OPEN_EDIT_FACILITY_DIALOG: {
      return {
        ...state,
        editFacilityDialog: {
          ...state.editFacilityDialog,
          type: 'edit',
          props: {
            open: true
          },
          data: action.data
        }
      };
    }
    case Actions.CLOSE_EDIT_FACILITY_DIALOG: {
      return {
        ...state,
        editFacilityDialog: {
          ...state.editFacilityDialog,
          type: 'edit',
          props: {
            open: false
          },
          data: null
        }
      };
    }
    case Actions.EDIT_FACILITY_SUPERVISOR: {
      const { facilityId, supervisor, groupId } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [groupId]: {
            ...state.entities[groupId],
            facilities: {
              ...state.entities[groupId].facilities,
              [facilityId]: {
                ...state.entities[groupId].facilities[facilityId],
                supervisor
              }
            }
          }
        }
      };
    }
    case RESET_STORE:
      return initialState;
    case Actions.ASSIGN_FACILITIES_TO_GROUP: {
      const { assignedFacilities, groupId, groupName, partnerId }: AssignFacilitiesToGroupActionProps = action.payload;

      const updatedEntities = _.cloneDeep(state.entities);

      if (groupName && !updatedEntities[groupId]) {
        updatedEntities[groupId] = {
          name: groupName,
          partner_id: partnerId ?? null,
          facilities: {}
        };
      }

      assignedFacilities.forEach(({ facilityId, groupId: currentGroupId }) => {
        const facility = updatedEntities[currentGroupId].facilities[facilityId];
        facility['group_id'] = groupId;
        updatedEntities[currentGroupId].facilities = _.omit(updatedEntities[currentGroupId].facilities, facilityId);

        _.set(updatedEntities, [groupId, 'facilities', facilityId], facility);
      });

      return {
        ...state,
        entities: updatedEntities
      };
    }
    case Actions.REMOVE_GROUP: {
      const updatedEntities = _.cloneDeep(state.entities);
      delete updatedEntities[action.payload];

      return {
        ...state,
        entities: updatedEntities
      };
    }
    case Actions.IS_CREATED: {
      const updatedEntities = Object.entries(state.entities).reduce((acc, [groupId, group]) => {
        const numericGroupId = Number(groupId);

        if (group.facilities[action.obid]) {
          acc[numericGroupId] = {
            ...group,
            facilities: {
              ...group.facilities,
              [action.obid]: {
                ...group.facilities[action.obid],
                status: FacilityCreationStatus.CREATED_AND_CACHED
              }
            }
          };
        } else {
          acc[numericGroupId] = group;
        }

        return acc;
      }, {} as NestedFacilitiesStatistics);

      return {
        ...state,
        entities: updatedEntities
      };
    }
    case Actions.CHANGE_GROUP_NAME: {
      const { groupId, groupName } = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [groupId]: {
            ...state.entities[groupId],
            name: groupName
          }
        }
      };
    }
    default:
      return state;
  }
};

export default partnerReducer;
