import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { ErrorResponse, FetchingState } from 'types';
import { convertToCamelCase } from 'utils/funcs/convertCase';

import { Product, SuggestedAccessory } from './types';

interface InitialState {
  productsFetchingStatus: FetchingState;
  products: Product[];
  suggestedAccessoriesStatus: FetchingState;
  suggestedAccessories: SuggestedAccessory[] | null;
  isOrderPossible: boolean;
  isOrderPossibleErrorMessage: string | null;
  orderPossibleFetchingStatus: FetchingState;
}

const initialState: InitialState = {
  productsFetchingStatus: FetchingState.INITIAL,
  products: [],
  suggestedAccessoriesStatus: FetchingState.INITIAL,
  suggestedAccessories: null,
  isOrderPossible: false,
  isOrderPossibleErrorMessage: null,
  orderPossibleFetchingStatus: FetchingState.INITIAL
};

export const fetchProducts = createAsyncThunk('products/fetch', async () => {
  const { data } = await axios.get('/api/products');
  return convertToCamelCase(data);
});

export const fetchSuggestedAccessories = createAsyncThunk(
  'products/fetchSuggestedAccessories',
  async (products: Record<string, number>) => {
    const { data } = await axios.post(`/api/order-bundles/suggestions`, {
      products
    });
    return convertToCamelCase(data);
  }
);

export const fetchOrderPossible = createAsyncThunk<
  boolean,
  Record<string, number>,
  { rejectValue: ErrorResponse | null }
>('products/fetchOrderPossible', async (products: Record<string, number>, { rejectWithValue }) => {
  try {
    const { data } = await axios.post('/api/orders/is-possible', {
      products
    });

    return convertToCamelCase(data);
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    return rejectWithValue(axiosError.response?.data || null);
  }
});

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetSuggestedAccessories(state) {
      state.suggestedAccessories = initialState.suggestedAccessories;
      state.suggestedAccessoriesStatus = initialState.suggestedAccessoriesStatus;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProducts.pending, state => {
        state.productsFetchingStatus = FetchingState.LOADING;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsFetchingStatus = FetchingState.SUCCEEDED;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, state => {
        state.productsFetchingStatus = FetchingState.FAILED;
        state.products = initialState.products;
      })
      .addCase(fetchSuggestedAccessories.pending, state => {
        state.suggestedAccessoriesStatus = FetchingState.LOADING;
      })
      .addCase(fetchSuggestedAccessories.fulfilled, (state, action) => {
        state.suggestedAccessoriesStatus = FetchingState.SUCCEEDED;
        state.suggestedAccessories = action.payload;
      })
      .addCase(fetchSuggestedAccessories.rejected, state => {
        state.suggestedAccessoriesStatus = FetchingState.FAILED;
        state.suggestedAccessories = initialState.suggestedAccessories;
      })
      .addCase(fetchOrderPossible.pending, state => {
        state.orderPossibleFetchingStatus = FetchingState.LOADING;
        state.isOrderPossibleErrorMessage = null;
      })
      .addCase(fetchOrderPossible.fulfilled, (state, action) => {
        state.orderPossibleFetchingStatus = FetchingState.SUCCEEDED;
        state.isOrderPossible = action.payload;
      })
      .addCase(fetchOrderPossible.rejected, (state, action) => {
        state.orderPossibleFetchingStatus = FetchingState.FAILED;
        state.isOrderPossible = initialState.isOrderPossible;
        state.isOrderPossibleErrorMessage = action.payload?.code || null;
      });
  }
});

export const { resetSuggestedAccessories } = productsSlice.actions;

export default productsSlice.reducer;
