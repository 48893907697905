import { UserRole } from 'types';
import { FacilityType, Instance, MultidashboardPath, Plmn, SimCards, Supervisor } from 'types';

export interface Partner {
  id: number;
  name: string;
}

export interface FacilityStatistics {
  activeForUser: boolean;
  additionalData: {
    batteryWeek: string;
    buttonEventsSevenDays: string;
    buttonEventsToday: string;
    buttonsAssigned: string;
    deviceAssigned: string;
    deviceTypesOnline: number[];
    msgsLastSevenDays: string;
    msgsToday: string;
    msgsTotal: string;
    plmn: Plmn;
    profiles: string;
    profilesLogged: string;
    simCards: string[];
    streaming: string;
    watchesAssigned: string;
    wifiCellularWatchesCount: number[];
  };
  brand: string | null;
  store_type: string;
  city: string;
  country: string;
  created_date: number | null;
  demoObjectId: number | null;
  externalId: string | null;
  facilityType: FacilityType;
  group_id: number;
  group_name: string | null;
  id: string;
  infrastructure: string | null;
  instance: Instance;
  lang: string;
  latitude: number;
  longitude: number;
  multidashboardPath: MultidashboardPath;
  object_name: string;
  partners: Partner[];
  processId: number;
  status: string;
  street: string;
  streetNumber: string;
  supervisor: Supervisor | null;
  zipCode: string;
  phone: string;
}

interface Group {
  name: string;
  partner_id: number | null;
  facilities: FacilityStatistics[];
}

export type FacilitiesStatisticsResponse = {
  [key: number]: Group;
};

export type NestedFacilitiesStatistics = {
  [key: number]: {
    name: string;
    partner_id: number | null;
    facilities: {
      [key: string]: FacilityStatistics;
    };
  };
};

export type BatteryPercent = number | null;

export interface FacilityNameCellProps {
  facilityType: FacilityType;
  earlyAdopter: boolean;
  externalId: string | null;
  name: string;
  address: {
    city: string;
    street: string;
    streetNumber: string;
  };
}

export interface EditFacilityDialogDataProps {
  lang: string;
  object_name: string;
  facilityType: FacilityType;
  activeForUser: boolean;
  earlyAdopter: boolean;
  partners?: Partner[];
  groupId: number;
  instance: Instance;
  facilityId: string;
  brand: string | null;
  storeType: string | null;
}

export interface FacilityActionsProps extends EditFacilityDialogDataProps {
  id: string;
  status: FacilityStatus | null;
  name: string;
  multidashboardPath: MultidashboardPath;
  country: string;
  simCards: SimCards;
  plmn: Plmn;
  isDisabled?: boolean;
}

interface CombinedFacilityProps extends FacilityNameCellProps, FacilityActionsProps {}

interface UniversalFacilityProps {
  supervisor: Supervisor | null;
  createdAt: number | null;
  todayMessages: number;
  weekMessages: number;
  onlineWatches: number;
  allWatches: number;
  onlineButtons: number;
  allButtons: number;
  onlineUsers: number;
  allUsers: number;
  todayButtonUsage: number;
  weekButtonUsage: number;
  battery: BatteryPercent;
  isExpanded?: boolean;
}

export interface FacilityRow extends UniversalFacilityProps, Partial<CombinedFacilityProps> {
  id: string;
  name: string;
  country: string;
  countries: Record<string, number>;
  groupId: number;
  partnerId?: number | null;
  totalAmountOfFacilities?: number;
  subRows?: SubFacilityRow[];
  isLastInGroup?: boolean;
}

export interface SubFacilityRow extends UniversalFacilityProps, CombinedFacilityProps {
  id: string;
  name: string;
  brand: string | null;
  storeType: string | null;
  country: string;
  partners: Partner[];
  countries: Record<string, number>;
  groupId: number;
}

type FacilityStatus = string;

export interface GroupDefines {
  group_id: number;
  themeButtonId: number | null;
  userPrivacyEnabled: boolean;
  userMessageHidingTimeEnabled: boolean | null;
  minutesUntilHidingMessages: boolean | null;
  buttonLanguages: string;
  is_recommending_facilities_enabled: boolean;
  is_bridge_enabled: boolean;
  is_rma_enabled: boolean;
  facilities: string[];
  facilitiesInBridge: string[];
}

export interface GroupSettings extends GroupDefines {
  name: string;
}

export interface AssignFacilitiesToGroupActionProps {
  groupId: number;
  assignedFacilities: AssignedFacilityType[];
  groupName?: string;
  partnerId?: number | null;
}

export interface AssignedFacilityType {
  facilityId: string;
  groupId: number;
}

export interface AddNewFacility {
  address: {
    city: string;
    country: string;
    googlePlaceId: string;
    phone: string;
    street: string;
    streetNumber: string;
    zipCode: string;
  };
  language: string;
  managers: string[];
  name: string;
  type: FacilityType;
  externalId?: string;
}

export interface AddManager {
  email: string;
  role: UserRole;
}

export enum PARTNER_TAB {
  FACILITIES = 'facilities',
  RMA = 'rma',
  ORDERS = 'orders'
}

export enum PARTNER_FACILITIES_TAB {
  TABLE = 'table',
  MAP = 'map'
}

export enum FacilityCreationStatus {
  CREATING = 'creating',
  CREATED = 'created',
  CREATED_AND_CACHED = 'createdAndCached'
}
