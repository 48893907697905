import { FormHelperText, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { COLOR_ERROR_RED_100 } from 'lib/colors';
import React from 'react';

import EnhancedTranslate from '../EnhancedTranslate';

interface AdditionalHelperTextProps {
  value: string;
  styles?: string;
}

const useStyles = makeStyles({
  helperText: {
    marginInline: 14,
    color: COLOR_ERROR_RED_100
  }
});

function AdditionalHelperText({ value, styles }: AdditionalHelperTextProps) {
  const classes = useStyles();

  return (
    <FormHelperText className={clsx(classes.helperText, styles)}>
      <EnhancedTranslate value={value} />
    </FormHelperText>
  );
}

export default AdditionalHelperText;
