import { Dialog, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EnhancedTranslate from 'app/common-components/EnhancedTranslate';
import * as DeviceActions from 'app/main/apps/devices/store/actions';
import * as PartnerActions from 'app/main/apps/partner/store/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import SerialsList from './SerialsList';

const ERROR_SERIAL_NOT_FOUND = 'error_serial_not_found';
const ERROR_SERIAL_ALREADY_ASSIGN = 'error_serial_already_assign';
const ERROR_MULTIPLE_SERIALS = 'error_multiple_serials';
const INCONSISTENT_WATCH_VERSIONS = 'inconsistent_watch_versions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function AddNewDevicesReportDialog() {
  const dispatch = useDispatch();
  const addingNewDevicesAddedSerials = useSelector(({ devices }) => devices.devices.addingNewDevicesAddedSerials);
  const addingNewDevicesFailedSerials = useSelector(({ devices }) => devices.devices.addingNewDevicesFailedSerials);

  const addingNewDevicesAddedSerialsPartner = useSelector(
    ({ partnerReducer }) => partnerReducer.pairDevicesAddedSerials
  );
  const addingNewDevicesFailedSerialsPartner = useSelector(
    ({ partnerReducer }) => partnerReducer.pairDevicesFailedSerials
  );

  function closeComposeDialog() {
    isDeviceTab()
      ? dispatch(DeviceActions.closeAddingNewDeviceBySerialRaportDialog())
      : dispatch(PartnerActions.closePairDeviceBySerialReportDialog());
  }

  function isDeviceTab() {
    return addingNewDevicesFailedSerials.length > 0 || addingNewDevicesAddedSerials.length > 0;
  }

  function isOpen() {
    return (
      addingNewDevicesFailedSerials.length > 0 ||
      addingNewDevicesAddedSerials.length > 0 ||
      addingNewDevicesAddedSerialsPartner.length > 0 ||
      addingNewDevicesFailedSerialsPartner.length > 0
    );
  }

  let addedSerials, failedSerials;
  if (isDeviceTab()) {
    addedSerials = addingNewDevicesAddedSerials;
    failedSerials = addingNewDevicesFailedSerials;
  } else {
    addedSerials = addingNewDevicesAddedSerialsPartner;
    failedSerials = addingNewDevicesFailedSerialsPartner;
  }

  const failedSerialsSerialNotFound = failedSerials.filter(x => x.reason === ERROR_SERIAL_NOT_FOUND);
  const failedSerialsAlreadyAssigned = failedSerials.filter(x => x.reason === ERROR_SERIAL_ALREADY_ASSIGN);
  const failedSerialsMultiple = failedSerials.filter(x => x.reason === ERROR_MULTIPLE_SERIALS);
  const failedSerialsInconsistentWatchVersions = failedSerials.filter(x => x.reason === INCONSISTENT_WATCH_VERSIONS);

  return (
    <Dialog
      open={isOpen()}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeComposeDialog}
      aria-labelledby='alert-dialog-slide-title'
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle id='alert-dialog-slide-title'>
        <EnhancedTranslate value='Add devices dialog report' />
      </DialogTitle>
      <DialogContent
        style={{
          background: 'white',
          color: 'rgba(0, 0, 0, 0.87)'
        }}
      >
        <DialogContentText
          style={{
            background: 'white',
            color: 'rgba(0, 0, 0, 0.87)'
          }}
        >
          <SerialsList serials={addedSerials} title={I18n.t('Added serials')} color='rgb(0, 200, 0)' />
          <br />
          <br />

          <SerialsList
            serials={failedSerialsSerialNotFound.map(item => item.serial)}
            title={I18n.t('Serials not found')}
            color='rgb(200, 0, 0)'
          />

          {failedSerialsAlreadyAssigned.length > 0 ? I18n.t('Serials currently assigned') + ':' : ''}
          {failedSerialsAlreadyAssigned.length > 0 ? (
            <p
              style={{
                color: 'rgb(200, 0, 0)'
              }}
            >
              {failedSerialsAlreadyAssigned.map(item => (
                <li key={item.serial}>
                  {item.serial} - <EnhancedTranslate value='Assigned to' />
                  &nbsp;
                  <b>{item.objectSerialIsAssignedTo ? item.objectSerialIsAssignedTo : I18n.t('other object')}</b>
                </li>
              ))}
            </p>
          ) : (
            ''
          )}
          <SerialsList
            serials={failedSerialsMultiple.map(item => item.serial)}
            title={I18n.t('Multiple devices found')}
            color='rgb(200, 0, 0)'
          />
          <SerialsList
            serials={failedSerialsInconsistentWatchVersions.map(item => item.serial)}
            title={I18n.t('Inconsistent watch versions')}
            color='rgb(200, 0, 0)'
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeComposeDialog} color='primary'>
          <EnhancedTranslate value='OK' />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewDevicesReportDialog;
