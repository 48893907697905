import _ from '@lodash';

export const convertToSnakeCase = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(convertToSnakeCase) as T;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj as Record<string, unknown>).map(([key, value]) => [
        _.snakeCase(key),
        convertToSnakeCase(value)
      ])
    ) as T;
  }
  return obj;
};

export const convertToCamelCase = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(convertToCamelCase) as T;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj as Record<string, unknown>).map(([key, value]) => [
        _.camelCase(key),
        convertToCamelCase(value)
      ])
    ) as T;
  }
  return obj;
};
