import React from 'react';
import { I18n } from 'react-redux-i18n';

interface SerialsListProps {
  serials: string[];
  color: string;
  title: string;
}

const SerialsList = ({ serials, color, title }: SerialsListProps) => {
  if (!serials?.length) return null;

  return (
    <>
      <div>{I18n.t(title) + ':'}</div>
      <p style={{ color }}>
        {serials.map(item => (
          <li key={item}>{item}</li>
        ))}
      </p>
    </>
  );
};

export default SerialsList;
