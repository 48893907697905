import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import { Dispatch } from 'redux';
import { Obid } from 'types';

import _ from '../../../../../@lodash';
import { LONG_ENDPOINT_TIMEOUT_60_SECONDS, UNRECOGNIZED_ERROR } from '../../../../../lib/constants';
import { getCurrentSelectedObid } from '../../../../../lib/getObid';
import { showMessage } from '../../../../store/actions';
import { Order, PlaceShipmentOrderForm, RMA_VIEW_MODE, RmaDetails, StatusHistory } from '../types';

interface InitialState {
  partnerOrders: Record<string, Order>;
  managerOrders: Record<string, Order>;
  rmaDetails: RmaDetails | null;
  rmaStatusHistory: StatusHistory[];
  estimatedArrivalDate: { service: string; returnService: string; deliveryDate: string; deliveryTime: string } | null;
  estimatedArrivalDateLoading: boolean;
  estimatedArrivalDateError: string | null;
  estimatedArrivalDateErrorFields: string[] | null;
  placingOrderError: string | null;
  placingOrderLoading: boolean;
  isRmaPossible: boolean;
  rmaImpossibleReason: string | null;
  supportedCountriesListDialogOpen: boolean;
  supportedCountries: [];
  rmaCreateDialog: {
    open: boolean;
    mode: RMA_VIEW_MODE | null;
  };
  rmaDetailsDialog: {
    open: boolean;
    rmaId: string;
    mode: RMA_VIEW_MODE | null;
  };
  changingRmaStatusLoading: boolean;
  rmaCancelDialogId: string | null;
  rmaAuthorizationDialogId: string | null;
}

export const initialState: InitialState = {
  partnerOrders: {},
  managerOrders: {},
  rmaDetails: null,
  rmaStatusHistory: [],
  estimatedArrivalDate: null,
  estimatedArrivalDateLoading: false,
  estimatedArrivalDateError: null,
  estimatedArrivalDateErrorFields: null,
  placingOrderError: null,
  placingOrderLoading: false,
  isRmaPossible: false,
  rmaImpossibleReason: null,
  supportedCountriesListDialogOpen: false,
  supportedCountries: [],
  rmaCreateDialog: {
    open: false,
    mode: null
  },
  rmaDetailsDialog: {
    open: false,
    rmaId: '',
    mode: null
  },
  changingRmaStatusLoading: false,
  rmaCancelDialogId: null,
  rmaAuthorizationDialogId: null
};

export const fetchRmaOrdersForPartner = () => async (dispatch: Dispatch) => {
  axios.get('/api/rma').then(res => {
    dispatch(setPartnerOrders(res.data));
  });
};

export const fetchRmaOrdersForManager = () => async (dispatch: Dispatch) => {
  axios
    .get('/api/rma', {
      params: {
        'facility-id': getCurrentSelectedObid()
      }
    })
    .then(res => {
      dispatch(setManagerOrders(res.data));
    });
};

export const fetchSupportedCountries = () => async (dispatch: Dispatch) => {
  axios.get('/api/rma/supported-countries').then(res => {
    dispatch(setSupportedCountries(res.data));
  });
};

export const fetchEstimatedArrivalDate =
  (obid: Obid, products: { [key: string]: number }, googlePlaceId?: string | null) => async (dispatch: Dispatch) => {
    dispatch(setEstimatedArrivalDateLoading(true));
    const postData = {
      obid,
      products,
      googlePlaceId
    };
    if (googlePlaceId === null) {
      delete postData.googlePlaceId;
    }
    axios
      .post('/api/rma/estimated-arrival', postData)
      .then(res => {
        dispatch(setEstimatedArrivalDate(res.data));
        dispatch(setEstimatedArrivalDateError(null));
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          dispatch(setEstimatedArrivalDateError(err.response.data.code));
          dispatch(setEstimatedArrivalDateErrorFields(err.response.data.fields));
        } else {
          console.error(err);
          Sentry.captureException(err);
        }
      })
      .finally(() => {
        dispatch(setEstimatedArrivalDateLoading(false));
      });
  };

export const placeShipmentOrder = (form: PlaceShipmentOrderForm, mode: RMA_VIEW_MODE) => async (dispatch: Dispatch) => {
  dispatch(setPlacingOrderLoading(true));
  axios
    .post(
      '/api/rma',
      {
        ...form
      },
      {
        timeout: LONG_ENDPOINT_TIMEOUT_60_SECONDS
      }
    )
    .then(() => {
      if (mode === RMA_VIEW_MODE.MANAGER) {
        dispatch(fetchRmaOrdersForManager() as any);
      } else {
        dispatch(fetchRmaOrdersForPartner() as any);
      }
      dispatch(setPlacingOrderError(null));
    })
    .catch(err => {
      if (err.response && err.response.status === 400) {
        dispatch(setPlacingOrderError(err.response.data.code));
      } else {
        dispatch(setPlacingOrderError(UNRECOGNIZED_ERROR));
      }
    })
    .finally(() => {
      dispatch(setPlacingOrderLoading(false));
    });
};

export const fetchRmaDetails = (rmaId: number) => async (dispatch: Dispatch) => {
  axios.get(`/api/rma/${rmaId}`).then(res => {
    dispatch(setRmaDetails(res.data));
  });
};

export const fetchRmaStatusHistory = (rmaId: number) => async (dispatch: Dispatch) => {
  axios.get(`/api/rma/${rmaId}/status-history`).then(res => {
    dispatch(setRmaStatusHistory(res.data));
  });
};

export const cancelRma = (rmaId: number) => async (dispatch: Dispatch) => {
  dispatch(setChangingRmaStatusLoading(true));
  axios
    .post(`/api/rma/${rmaId}/cancel`)
    .then(res => {
      dispatch(
        setRmaStatus({
          rmaId: rmaId,
          changedBy: res.data.changedBy,
          date: res.data.date,
          newState: res.data.newState,
          subState: res.data.subState
        })
      );
      dispatch(
        showMessage({
          message: I18n.t('The order was canceled successfully'),
          autoHideDuration: 6000,
          variant: 'success'
        })
      );
    })
    .catch(() => {
      dispatch(
        showMessage({
          message: I18n.t('An error occurred while canceling the order'),
          autoHideDuration: 6000,
          variant: 'error'
        })
      );
    })
    .finally(() => {
      dispatch(setChangingRmaStatusLoading(false));
    });
};

export const authorizeRma = (rmaId: number) => async (dispatch: Dispatch) => {
  dispatch(setChangingRmaStatusLoading(true));
  axios
    .post(`/api/rma/${rmaId}/authorize`)
    .then(res => {
      dispatch(
        setRmaStatus({
          rmaId: rmaId,
          changedBy: res.data.changedBy,
          date: res.data.date,
          newState: res.data.newState,
          subState: res.data.subState
        })
      );
      dispatch(
        showMessage({
          message: I18n.t('The order was authorized successfully'),
          autoHideDuration: 6000,
          variant: 'success'
        })
      );
    })
    .catch(() => {
      dispatch(
        showMessage({
          message: I18n.t('An error occurred while authorizing the order'),
          autoHideDuration: 6000,
          variant: 'error'
        })
      );
    })
    .finally(() => {
      dispatch(setChangingRmaStatusLoading(false));
    });
};

export const fetchIsRmaPossible = (obid: Obid, products: { [key: string]: number }) => async (dispatch: Dispatch) => {
  axios
    .post(`/api/objects/${obid}/is-rma-possible`, {
      products,
      obid
    })
    .then(() => {
      dispatch(setIsRmaPossible(true));
      dispatch(setRmaImpossibleReason(null));
    })
    .catch(err => {
      dispatch(setIsRmaPossible(false));
      dispatch(setRmaImpossibleReason(err.response.data.code));
    });
};

const rmaSlice = createSlice({
  name: 'rma',
  initialState,
  reducers: {
    setRmaImpossibleReason: (state, action) => {
      state.rmaImpossibleReason = action.payload;
    },
    setIsRmaPossible: (state, action) => {
      state.isRmaPossible = action.payload;
    },
    setPartnerOrders: (state, action) => {
      state.partnerOrders = _.keyBy(action.payload, 'id');
    },
    setManagerOrders: (state, action) => {
      state.managerOrders = _.keyBy(action.payload, 'id');
    },

    setRmaDetails: (state, action) => {
      state.rmaDetails = action.payload;
    },
    setRmaStatusHistory: (state, action) => {
      state.rmaStatusHistory = action.payload;
    },
    setEstimatedArrivalDate: (state, action) => {
      state.estimatedArrivalDate = action.payload;
    },
    setEstimatedArrivalDateLoading: (state, action) => {
      state.estimatedArrivalDateLoading = action.payload;
    },
    setEstimatedArrivalDateError: (state, action) => {
      state.estimatedArrivalDateError = action.payload;
    },
    setEstimatedArrivalDateErrorFields: (state, action) => {
      state.estimatedArrivalDateErrorFields = action.payload;
    },

    openRmaCreateDialog: (state, action) => {
      state.rmaCreateDialog = {
        open: true,
        mode: action.payload
      };
    },
    openRmaDetailsDialog: (state, action) => {
      state.rmaDetailsDialog = {
        ...state.rmaDetailsDialog,
        mode: action.payload.mode,
        rmaId: action.payload.rmaId
      };
    },
    closeRmaCreateDialog: state => {
      state.rmaCreateDialog = initialState.rmaCreateDialog;
      state.isRmaPossible = false;
      state.rmaImpossibleReason = null;
    },
    closeRmaDetailsDialog: state => {
      state.rmaDetailsDialog = initialState.rmaDetailsDialog;
      state.rmaDetails = initialState.rmaDetails;
    },
    setPlacingOrderError: (state, action) => {
      state.placingOrderError = action.payload;
    },
    setPlacingOrderLoading: (state, action) => {
      state.placingOrderLoading = action.payload;
    },
    setSupportedCountriesListDialogOpen: (state, action) => {
      state.supportedCountriesListDialogOpen = action.payload;
    },
    setSupportedCountries: (state, action) => {
      state.supportedCountries = action.payload;
    },
    openRmaCancelDialog: (state, action) => {
      state.rmaCancelDialogId = action.payload;
    },
    closeRmaCancelDialog: state => {
      state.rmaCancelDialogId = null;
    },
    openRmaAuthorizationDialog: (state, action) => {
      state.rmaAuthorizationDialogId = action.payload;
    },
    closeRmaAuthorizationDialog: state => {
      state.rmaAuthorizationDialogId = null;
    },
    setChangingRmaStatusLoading: (state, action) => {
      state.changingRmaStatusLoading = action.payload;
    },
    setRmaStatus: (state, action) => {
      const { rmaId, newState, subState, changedBy, date } = action.payload;

      if (state.partnerOrders && rmaId in state.partnerOrders) state.partnerOrders[rmaId].status = newState;

      if (state.managerOrders && rmaId in state.managerOrders) state.managerOrders[rmaId].status = newState;

      if (state.rmaDetails) {
        state.rmaDetails.status = newState;
      }
      state.rmaStatusHistory = [
        ...state.rmaStatusHistory,
        {
          newState: newState,
          subState: subState,
          changedBy: changedBy,
          date: date,
          code: null,
          location: null
        }
      ];
    }
  }
});

export const {
  setPartnerOrders,
  setManagerOrders,
  setRmaDetails,
  setRmaStatusHistory,
  setEstimatedArrivalDate,
  setEstimatedArrivalDateLoading,
  setEstimatedArrivalDateError,
  openRmaCreateDialog,
  openRmaDetailsDialog,
  closeRmaCreateDialog,
  closeRmaDetailsDialog,
  setPlacingOrderError,
  setPlacingOrderLoading,
  openRmaCancelDialog,
  closeRmaCancelDialog,
  openRmaAuthorizationDialog,
  closeRmaAuthorizationDialog,
  setEstimatedArrivalDateErrorFields,
  setRmaImpossibleReason,
  setIsRmaPossible,
  setSupportedCountriesListDialogOpen,
  setSupportedCountries,
  setRmaStatus,
  setChangingRmaStatusLoading
} = rmaSlice.actions;
export default rmaSlice.reducer;
