import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { FetchingState } from 'types';
import { convertToCamelCase } from 'utils/funcs/convertCase';

import { DeliveryDate, ORDER_TYPE, Order, OrderDetails, OrderHistory } from '../types';

interface InitialState {
  orders: Order[];
  ordersFetchingStatus: FetchingState;
  isCreateOrderDialogOpen: boolean;
  isCreateDemoKitDialogOpen: boolean;
  orderDetailsFetchingStatus: FetchingState;
  openedOrderId: number | null;
  isOrderDetailsDialogOpen: boolean;
  orderDetails: OrderDetails | null;
  orderHistoryList: OrderHistory[];
  orderHistoryFetchingStatus: FetchingState;
  deliveryDate: DeliveryDate | null;
  deliveryDateFetchingStatus: FetchingState;
}

const initialState: InitialState = {
  orders: [],
  ordersFetchingStatus: FetchingState.INITIAL,
  orderDetailsFetchingStatus: FetchingState.INITIAL,
  isCreateOrderDialogOpen: false,
  isCreateDemoKitDialogOpen: false,
  openedOrderId: null,
  isOrderDetailsDialogOpen: false,
  orderDetails: null,
  orderHistoryList: [],
  orderHistoryFetchingStatus: FetchingState.INITIAL,
  deliveryDate: null,
  deliveryDateFetchingStatus: FetchingState.INITIAL
};

export const fetchOrders = createAsyncThunk('partner/orders/fetchOrders', async () => {
  const { data } = await axios.get<Order[]>('/api/orders');
  return convertToCamelCase(data);
});

export const fetchOrderHistory = createAsyncThunk('partner/orders/fetchOrderHistory', async (orderId: number) => {
  const { data } = await axios.get<OrderHistory[]>(`/api/orders/${orderId}/status-history`);
  return convertToCamelCase(data);
});

export const fetchOrder = createAsyncThunk('partner/orders/fetchOrder', async (orderId: number) => {
  const { data } = await axios.get<OrderDetails>(`/api/orders/${orderId}`);
  return convertToCamelCase(data);
});

export const fetchDeliveryDate = createAsyncThunk(
  'partner/orders/fetchDeliveryDate',
  async ({ googlePlaceId, products }: { googlePlaceId: string; products: Record<string, number> }) => {
    if (!googlePlaceId || !Object.keys(products).length) throw new Error('Missing required data');

    const { data } = await axios.post<DeliveryDate>('/api/orders/estimated-arrival', {
      google_place_id: googlePlaceId,
      products
    });
    return data;
  }
);

const createDialogSlice = createSlice({
  name: 'createDialog',
  initialState,
  reducers: {
    openCreateOrderDialog: (state, action) => {
      if (action.payload === ORDER_TYPE.DEVICES) {
        state.isCreateOrderDialogOpen = true;
      }
      if (action.payload === ORDER_TYPE.DEMO_KIT) {
        state.isCreateDemoKitDialogOpen = true;
      }
    },
    closeCreateOrderDialog: state => {
      state.isCreateOrderDialogOpen = false;
      state.isCreateDemoKitDialogOpen = false;
    },
    openOrderDetailsDialog: (state, action) => {
      state.orderDetails = null;
      state.orderHistoryList = [];
      state.isOrderDetailsDialogOpen = true;
      state.openedOrderId = action.payload;
    },
    closeOrderDetailsDialog: state => {
      state.isOrderDetailsDialogOpen = false;
      state.openedOrderId = null;
      state.orderDetailsFetchingStatus = FetchingState.INITIAL;
      state.orderHistoryFetchingStatus = FetchingState.INITIAL;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOrders.pending, state => {
      state.ordersFetchingStatus = FetchingState.LOADING;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
      state.ordersFetchingStatus = FetchingState.SUCCEEDED;
    });
    builder.addCase(fetchOrders.rejected, state => {
      state.ordersFetchingStatus = FetchingState.FAILED;
    });
    builder.addCase(fetchOrder.pending, state => {
      state.orderDetailsFetchingStatus = FetchingState.LOADING;
    });
    builder.addCase(fetchOrder.fulfilled, (state, action) => {
      state.orderDetailsFetchingStatus = FetchingState.SUCCEEDED;
      state.orderDetails = action.payload;
    });
    builder.addCase(fetchOrder.rejected, state => {
      state.orderDetailsFetchingStatus = FetchingState.FAILED;
    });
    builder.addCase(fetchOrderHistory.pending, state => {
      state.orderHistoryFetchingStatus = FetchingState.LOADING;
    });
    builder.addCase(fetchOrderHistory.fulfilled, (state, action) => {
      state.orderHistoryFetchingStatus = FetchingState.SUCCEEDED;
      state.orderHistoryList = action.payload;
    });
    builder.addCase(fetchOrderHistory.rejected, state => {
      state.orderHistoryFetchingStatus = FetchingState.FAILED;
    });
    builder.addCase(fetchDeliveryDate.pending, state => {
      state.deliveryDateFetchingStatus = FetchingState.LOADING;
    });
    builder.addCase(fetchDeliveryDate.fulfilled, (state, action) => {
      state.deliveryDate = action.payload;
      state.deliveryDateFetchingStatus = FetchingState.SUCCEEDED;
    });
    builder.addCase(fetchDeliveryDate.rejected, state => {
      state.deliveryDateFetchingStatus = FetchingState.FAILED;
    });
  }
});

export const { closeCreateOrderDialog, openCreateOrderDialog, closeOrderDetailsDialog, openOrderDetailsDialog } =
  createDialogSlice.actions;

export default createDialogSlice.reducer;
