import { FacilityType } from 'types';

enum ORDER_STATUS {
  FOR_AUTHORIZATION = 'forAuthorization',
  CREATED = 'created',
  AUTHORIZED = 'authorized',
  CANCELED = 'canceled',
  INTEGRATION_PROBLEM = 'integrationProblem',
  WAIT_FOR_PACKAGING = 'waitForPackaging',
  PRODUCT_NOT_AVAILABLE = 'productNotAvailable',
  PACKAGING = 'packaging',
  WAITING_FOR_STORE = 'waitingForStore',
  WAITING_FOR_COURIER = 'waitingForCourier',
  PICKED_BY_COURIER = 'pickedByCourier',
  IN_TRANSIT = 'inTransit',
  OUT_FOR_DELIVERY = 'outForDelivery',
  COMPLETED = 'completed',
  CANCELED_OR_REJECTED_BY_FACILITY = 'canceledOrRejectedByFacility',
  UPS_DELIVERY_PROBLEM = 'UPSDeliveryProblem',
  EXCEPTION = 'EXCEPTION',
  PACKAGE_QUANTITY_MISMATCHED = 'PACKAGE_QUANTITY_MISMATCHED',
  PACKAGE_RETURNED_GRACEFULLY = 'PACKAGE_RETURNED_GRACEFULLY'
}

export interface OrderedProduct {
  productId: number;
  quantity: number;
}

export interface Order {
  id: number;
  facilityId: string | null;
  created: string;
  phone: string;
  createdBy: number;
  shipmentStartDate: number;
  shipmentEndDate: number;
  customPersonName: string;
  customAddressName: string;
  creatorName: string;
  creatorPartnerName: string | null;
  facilityPartnerName: string | null;
  objectName: string | null;
  facilityExternalId: string | null;
  facilityType: FacilityType | null;
  facilityInstance: string | null;
  city: string;
  street: string;
  streetNumber: string;
  status: ORDER_STATUS;
  subStatus: string;
  products: OrderedProduct[];
}

export enum ORDER_TYPE {
  DEVICES = 'DEVICES',
  DEMO_KIT = 'DEMO_KIT'
}

export interface DevicesStepForm {
  facilityId: string | null;
  isOrderNotAssignedToFacility: boolean;
  products: Record<string, number>;
}

export interface DeliveryStepForm {
  email: string;
  googlePlaceId: string;
  contactPersonName: string;
  phone: string;
  dialCode: string;
  addressName: string;
  additionalShipmentInformation: string;
  customDeliveryAddress: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
  isAddressNameEditing: boolean;
  isDeliveryAddressEditing: boolean;
  isDeliveryDateEditing: boolean;
  deliveryDate?: string;
  customDeliveryName?: string;
  customPersonName?: string;
  contactPersonId?: number;
}

export type CreateOrderForm = DevicesStepForm & DeliveryStepForm;

export interface OrderDetails {
  additionalShipmentInformation: string;
  addressName: string;
  city: string;
  contactPersonId: number;
  contactPersonName: string;
  country: string;
  courierId: number | null;
  created: string;
  createdBy: number;
  customAddressName: string;
  customPersonName: string;
  email: string;
  facilityId: string;
  googlePlaceId: string;
  id: number;
  products: { productId: number; quantity: number }[];
  objectName: string;
  phone: string;
  shipmentEndDate: number;
  shipmentStartDate: number;
  shortLinkId: number | null;
  status: ORDER_STATUS;
  street: string;
  streetNumber: string;
  subStatus: string;
  zipCode: string;
}

export interface OrderHistory {
  status: ORDER_STATUS;
  subStatus: string;
  code: string | null;
  location: string | null;
  changedBy: number;
  date: string;
}
export interface DeliveryDate {
  service: string;
  deliveryDate: string;
  deliveryTime: string;
}
