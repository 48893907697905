import { Obid } from 'types';

export enum RMA_VIEW_MODE {
  PARTNER = 'partner',
  MANAGER = 'manager'
}

export interface Product {
  productId: number;
  quantity: number;
}

export interface Order {
  city: string;
  created: string;
  createdBy: number;
  creatorName: string;
  customAddressName: string;
  customPersonName: string;
  id: number;
  obid: string;
  object_name: string;
  phone: string;
  productId: 1;
  products: Product[];
  quantity: number;
  returnPackageId: number;
  returnPackageStatus: string;
  returnShipmentTrackingId: string;
  returnedDevices: number;
  shipmentDate: string;
  shipmentEndDate: number;
  shipmentStartDate: number;
  shipmentTrackingId: string;
  status: string;
  subState: string;
}

export interface RmaDetails {
  id: number;
  obid: string;
  created: string;
  status: string;
  shipmentDate: string;
  shipmentStartDate: number;
  shipmentEndDate: number;
  additionalShipmentInformation: string;
  email: string;
  phone: string;
  upsIdTo: string;
  customPersonName: string;
  customAddressName: string;
  shortLinkId: string;
  object_name: string;
  addressName: string;
  city: string;
  country: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  contactPersonName: null;
  products: Product[];
}

export interface StatusHistory {
  newState: string;
  subState: string;
  code: string | null;
  location: string | null;
  changedBy: number;
  date: string;
}

export interface PlaceShipmentOrderForm {
  additionalShipmentInformation: string;
  addressName: string;
  contactPersonId: number;
  devicesRemarks: { serial: string; remarks: string }[];
  email: string;
  googlePlaceId: string;
  obid: Obid;
  phone: string;
  products: Record<number, number>;
  reason: string;
  supportTicketId?: string;
  remarks?: string;
  customAddressName?: string;
  customPersonName?: string;
}

export interface AdditionalProduct {
  name: string;
  quantity: number;
}
