import auth from 'app/auth/store/reducers';
import recommendDialogSlice from 'app/fuse-layouts/shared-components/recommend/store/recommendDialogSlice';
import orderDevicesSlice from 'app/main/apps/devices/store/orderDevicesSlice';
import rvmSlice from 'app/main/apps/devices/store/rvmSlice';
import businessCardSlice from 'app/main/apps/partner/store/businessCardSlice';
import { i18nReducer } from 'react-redux-i18n';
import { Reducer, combineReducers } from 'redux';

import loginSlice from '../../auth/store/LoginSlice';
import userSlice from '../../auth/store/UserSlice';
import channelsNew from '../../main/apps/channels/store/reducers/channels.reducer';
import predefinedMessages from '../../main/apps/communication/predefinedMessages/store/reducers/predefinedMessages.reducer';
import accessReducer from '../../main/apps/dashboardAccess/store/reducers/access.reducer';
import devicesSlice from '../../main/apps/devices/store/DevicesSlice';
import devices from '../../main/apps/devices/store/reducers/index';
import jobs from '../../main/apps/jobTitles/store/reducers/jobtitle.reducer';
import ordersTabSlice from '../../main/apps/partner/ordersTab/store/ordersTabSlice';
import partnerSlice from '../../main/apps/partner/store/PartnerSlice';
import assignToGroupSlice from '../../main/apps/partner/store/assignToGroupSlice';
import facilityManagementSlice from '../../main/apps/partner/store/facilityManagementSlice';
import groupDialogSlice from '../../main/apps/partner/store/groupDialogSlice';
import groupPredefinedMessagesSlice from '../../main/apps/partner/store/groupPredefinedMessagesSlice';
import partnerReducer from '../../main/apps/partner/store/reducers/partner.reducer';
import removeGroupSlice from '../../main/apps/partner/store/removeGroupSlice';
import personnelReducer from '../../main/apps/personnel/store/reducers/personnel.reducer';
import personnelOnline from '../../main/apps/personnelOnline/store/reducers/personnelOnline.reducer';
import rmaSlice from '../../main/apps/rma/store/rmaSlice';
import buttonThemeSlice from '../../main/apps/settings/settings/service/themeSettings/store/buttonThemeSlice';
import brandsSlice from '../slices/brandsSlice';
import facilityDetailsSlice from '../slices/facilityDetailsSlice';
import productsSlice from '../slices/products/productsSlice';
import common from './common';
import objectsSlice from './common/objectsSlice';
import forSelect from './forSelect';
import fuse from './fuse';
import shadowService from './shadowService';

interface AsyncReducers {
  [key: string]: Reducer;
}

const createReducer = (asyncReducers: AsyncReducers) =>
  combineReducers({
    auth,
    fuse,
    common,
    forSelect,
    shadowService,
    ...asyncReducers,
    i18n: i18nReducer,
    predefinedMessages,
    personnelReducer,
    jobs,
    channelsNew,
    personnelOnline,
    access: accessReducer,
    partnerReducer,
    devices,
    rma: rmaSlice,
    objects: objectsSlice,
    partner: partnerSlice,
    facilityDetails: facilityDetailsSlice,
    businessCard: businessCardSlice,
    products: productsSlice,
    login: loginSlice,
    user: userSlice,
    devicesSlice: devicesSlice,
    recommendDialog: recommendDialogSlice,
    orderDevices: orderDevicesSlice,
    rvm: rvmSlice,
    groupSettingsDialog: groupDialogSlice,
    assignToGroupDialog: assignToGroupSlice,
    facilityManagement: facilityManagementSlice,
    groupPredefinedMessages: groupPredefinedMessagesSlice,
    removeGroup: removeGroupSlice,
    ordersTab: ordersTabSlice,
    buttonTheme: buttonThemeSlice,
    brands: brandsSlice
  });

export default createReducer;
