import { makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
  rowHeight: {
    height: '7.6rem'
  }
});

export const useIsTableResizable = () => {
  const isResizable = useMediaQuery('(min-width:968px)');
  const classes = useStyles();

  return {
    isResizable,
    headerClassName: isResizable ? classes.rowHeight : 'rt-resizable-header-content min-h-48'
  };
};
