import { FormControl } from '@material-ui/core';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { I18n } from 'react-redux-i18n';

import { countries } from '../../../lib/countries';
import AdditionalHelperText from './AdditionalHelperText';

export default function PhoneInputWithFlagsAndCountryCodes({ error = false, helperText = '', ...props }) {
  function getCountryPhoneLocalization() {
    let localizationObject = {};
    for (let country of countries) {
      localizationObject[country.countryCode] = I18n.t(country.countryCode.toUpperCase());
    }
    return localizationObject;
  }

  return (
    <FormControl error={error} fullWidth>
      <PhoneInput
        localization={getCountryPhoneLocalization()}
        inputStyle={{
          width: '100%',
          borderColor: error ? 'red' : undefined
        }}
        {...props}
      />
      {error && <AdditionalHelperText value={helperText} />}
    </FormControl>
  );
}
